import React, { useState, useEffect, useRef } from "react";
import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/800.css";
import "@fontsource/inter/900.css";
import "@fontsource/material-icons-rounded";
import "./styles/master.scss";
import "./styles/index.scss";

import firebaseConfig from "./components/FirebaseConfig";
import generateNewCode from "./functions/generateNewCode";
import makeHttpsUrl from "./functions/makeHttpsUrl";
import generateQR from "./functions/generateQR";
import addSpaceBetweenCode from "./functions/addSpaceBetweenCode";
import copyToClipboard from "./functions/copyToClipboard";

import { initializeApp } from "firebase/app";
import { getAuth, signInAnonymously, onAuthStateChanged } from "firebase/auth";
import { ref, onValue, update, remove, getDatabase } from "firebase/database";
import { AnimatePresence, motion } from "framer-motion";

import Header from "./components/Header";
import Footer from "./components/Footer";
import LinkBlock from "./components/LinkBlock";


const App = () => {
  const [user, setUser] = useState(null);
  const app = initializeApp(firebaseConfig);
  const auth = getAuth();
  const database = getDatabase(app);

  useEffect(() => {
    // set user on auth state change
    signInAnonymously(auth);

    onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
  }, [auth]);

  const [newLink, setNewLink] = useState("");

  // get myCode from local storage
  const [myCode, setMyCode] = useState(localStorage.getItem("mySllinkyCode"));



  // set mySllinkyCode to local storage
  useEffect(() => {
    if (myCode) return;

    let newCode = generateNewCode();
    setMyCode(newCode);
    localStorage.setItem("mySllinkyCode", newCode);
  }, [myCode]);

  const newLinkRef = useRef(null);



  const shareNewLink = () => {
    if (!newLink) {
      newLinkRef.current.focus();
      return;
    }

    // share the new link
    sendNewLink(newLink);

    // cleanup and set focus
    setNewLink("");
    setSlinkyCode("");
    newLinkRef.current.focus();
  };

  const sendNewLink = (newLink) => {
    if (!user) return;
    if (!myCode) return;

    const linksRef = ref(database, "codes/" + myCode + "/links");
    let timestamp = new Date().getTime();

    update(linksRef, {
      [timestamp]: {
        url: newLink,
      },
    });

    fetchUrlTitle(makeHttpsUrl(newLink), timestamp);

    setIsAdmin(true);
    getMyLinks();
    showMyQR();
  };

  const sendMyCode = () => {
    if (!user) return;
    if (!myCode) return;

    const codesRef = ref(database, "users/");

    update(codesRef, {
      [user.uid]: myCode,
    });
  };

  useEffect(() => {
    if (!user) return;
    if (!myCode) return;

    sendMyCode();
  }, [user, myCode, sendMyCode]);



  const [isAdmin, setIsAdmin] = useState(false);
  const [myLinks, setMyLinks] = useState([]);

  const [myLinkCount, setMyLinkCount] = useState(0);

  const getLinks = (code) => {
    code === myCode ? setIsAdmin(true) : setIsAdmin(false);

    const linksRef = ref(database, "codes/" + code + "/links");

    onValue(linksRef, (snapshot) => {
      const links = snapshot.val();

      let linksList = [];

      for (let key in links) {
        let item = {
          timestamp: parseInt(key),
          url: makeHttpsUrl(links[key].url),
          ...(links[key].title ? { title: links[key].title } : "")
        }
        linksList.unshift(item);
      }

      setMyLinks(linksList);
      if (newLinkFromURL) {
        clearNudgeAfter(1000);
        setNewLinkFromURL(null);
      };
    });
  };

  const clearNudgeAfter = (duration = 2000) => {
    setTimeout(() => {
      setNudge(null);
    }, duration);
  }

  const getMyLinks = () => {
    setSlinkyCode("");
    getLinks(myCode);
    setQrCodeZoomedIn(false);
  };

  useEffect(() => {
    if (!myCode) return;
    getLinks(myCode);
  }, [myCode]);

  const removeLink = (id) => {
    if (!user) return;
    if (!myCode) return;

    const linksRef = ref(database, "codes/" + myCode + "/links" + "/" + id);

    remove(linksRef);
  };

  const removeAllLinks = () => {
    if (!user) return;
    if (!myCode) return;

    let confirmation = window.confirm(
      `Are you sure you want to remove ${myLinks.length === 1 ? 'this link' : `all ${myLinks.length} links`}? This cannot be undone.`
    );

    if (!confirmation) return;

    const linksRef = ref(database, "codes/" + myCode + "/links");
    remove(linksRef);
  };

  const checkCodeFromURL = () => {
    let url = new URL(window.location.href);
    let code = url.searchParams.get("code");
    if (code && code.length === 9) {
      return code;
    }
    return false;
  };

  // remove code from url
  useEffect(() => {
    if (!checkCodeFromURL()) return;
    window.history.replaceState({}, "", window.location.pathname);
  }, []);

  const [slinkyCode, setSlinkyCode] = useState(checkCodeFromURL());
  const slinkyCodeRef = useRef(null);

  useEffect(() => {
    if (slinkyCode && slinkyCode.length === 9) {
      getLinks(slinkyCode);
    } else {
      if (!isAdmin) {
        setMyLinks([]);
        getMyLinks();
      }
    }
  }, [slinkyCode]);

  useEffect(() => {
    if (myLinks.length && isAdmin) {
      showMyQR();
    }

    if (isAdmin) {
      setMyLinkCount(myLinks.length);
    }
  }, [myLinks]);

  const [isMyCodeVisible, setIsMyCodeVisible] = useState(false);
  const [myQrImage, setMyQrImage] = useState("");



  const showMyQR = () => {
    if (!myCode) return;
    setMyQrImage(generateQR(myCode));
    setIsMyCodeVisible(true);
  };

  useEffect(() => {
    if (!isAdmin) setIsMyCodeVisible(false);
  }, [isAdmin]);

  const [loadingMessage, setLoadingMessage] = useState(
    "🔭 Looking for links..."
  );

  const getLoadingMessage = () => {
    setTimeout(() => {
      setLoadingMessage("🕵🏻‍♀️ No links found on this Sllinky code.");
    }, 5000);

    return loadingMessage;
  };

  const handleNewLinkForm = (e) => {
    e.preventDefault();
    shareNewLink();
  };

  const clearSlinkyCode = () => {
    setSlinkyCode("");
    slinkyCodeRef.current.focus();

    if (!isAdmin) {
      getMyLinks();
    }
  };

  const codeSeparator = " ";

  const handleSetSlinkyCode = (e) => {
    let codeValue = e.target.value.trim();
    if (codeValue.length <= 9) {
      setSlinkyCode(codeValue);
    } else {
      setSlinkyCode(codeValue.slice(0, 9));
    }
  };

  const [qrCodeZoomedIn, setQrCodeZoomedIn] = useState(false);

  const openAllLinks = () => {
    if (!myLinks.length) return;

    if (myLinks.length === 1) {
      window.open(myLinks[0].url, "_blank");
      return;
    }

    myLinks.forEach((link, index) => {
      window.open(link.url, `_blank${index}`);
    });
  };

  const getOtherSideLinksTitle = () => {
    let title = `${myLinks.length === 1 ? "Link" : "Links"
      } from the other side`;
    return title;
  };

  useEffect(() => {
    if (slinkyCode.length !== 9) return;
    slinkyCodeRef.current.blur();
  }, [slinkyCode]);

  const checkNewLinkFromURL = () => {
    let url = new URL(window.location.href);
    let link = url.searchParams.get("newLink");
    if (link) {
      return link;
    }
    return false;
  };


  const [newLinkFromURL, setNewLinkFromURL] = useState(checkNewLinkFromURL());

  useEffect(() => {
    if (!newLinkFromURL) return;

    setNudge(`Sharing ${newLinkFromURL.substring(0, 35)}...`);
    window.history.replaceState({}, "", window.location.pathname);
  }, [newLinkFromURL]);


  useEffect(() => {
    if (!user) return;
    if (!newLinkFromURL) return;
    sendNewLink(newLinkFromURL);
  }, [user]);


  const [recentSllinkyCodes, setRecentSllinkyCodes] = useState(() => {
    let currentNames = JSON.parse(localStorage.getItem('recentSllinkyCodes'));
    if (currentNames) {
      return currentNames;
    } else {
      return [];
    }
  });

  // update localstorage recentSllinkyCodes when state changes
  useEffect(() => {
    if (!recentSllinkyCodes) return;
    localStorage.setItem("recentSllinkyCodes", JSON.stringify(recentSllinkyCodes));
  }, [recentSllinkyCodes]);


  useEffect(() => {
    if (!slinkyCode) return;
    if (slinkyCode === myCode) return;

    let allCodes = recentSllinkyCodes.slice(0, 3);
    allCodes.unshift(slinkyCode);

    setRecentSllinkyCodes([...new Set(allCodes)]);
  }, [myLinks]);

  const [nudge, setNudge] = useState("");


  const parseTextFromHtml = (html) => {
    let parser = new DOMParser().parseFromString(html, "text/html");
    return parser.documentElement.textContent;
  };

  const TITLE_API_URL = "/api/title?url=";

  const fetchUrlTitle = async (requestUrl, id) => {
    await fetch(TITLE_API_URL + requestUrl).then(response => {
      if (!response.ok) return;

      response.json().then(data => {
        let linksRef = ref(database, "codes/" + myCode + "/links/" + id);
        update(linksRef, {
          title: parseTextFromHtml(data.title),
        });
      }).catch(err => {
        console.log(err);
      });
    });
  }

  return (
    <div>
      <div className="main">
        <div className="slinky-card">
          <Header nudge={nudge} setNudge={setNudge} />

          <section className="main-wrapper">
            <div className="inputs">
              {/* new link form */}
              <form
                onSubmit={handleNewLinkForm}
                className={`newLinkForm ${newLink ? "has-value" : ""}`}
              >
                <span className="input-icon material-icons">add</span>
                <label htmlFor="newLinkInput">Share a link</label>
                <input
                  type="url"
                  name="newLinkInput"
                  value={newLink}
                  onChange={(e) => setNewLink(e.target.value.trim())}
                  ref={newLinkRef}
                  inputMode="url"
                  autoComplete="url"
                  placeholder=""
                />

                <button className="input-btn" onClick={shareNewLink} tabIndex={-1}>
                  <span className="material-icons">expand_circle_down</span>
                </button>
              </form>

              <div
                className={`slinkyCodeForm ${slinkyCode ? "has-value" : ""}`}
              >
                {/* slinkyCode form */}
                <span className="input-icon material-icons">dialpad</span>
                <label htmlFor="slinkyCodeInput">Enter Sllinky code</label>
                <input
                  type="number"
                  name="slinkyCodeInput"
                  value={slinkyCode}
                  onChange={(e) => handleSetSlinkyCode(e)}
                  ref={slinkyCodeRef}
                  required
                  maxLength={9}
                  inputMode="tel"
                  pattern="[0-9]*"
                  min="0"
                  placeholder="000000000"
                  list="recentCodes"
                />

                {
                  recentSllinkyCodes.length > 0 && (
                    <datalist id="recentCodes">
                      {recentSllinkyCodes.map(code => (
                        <option value={code} key={code} />
                      ))}
                    </datalist>
                  )
                }

                {slinkyCode && (
                  <button className="input-btn" onClick={clearSlinkyCode} tabIndex={-1}>
                    <span className="material-icons">close</span>
                  </button>
                )}
              </div>
            </div>



            {isMyCodeVisible && (
              <AnimatePresence>
                <motion.div
                  className="my-qr-block"
                  initial={{ scale: 0.9, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  transition={{ duration: 0.4, ease: "circOut" }}
                  exit={{ scale: 0.9, opacity: 0 }}
                >
                  <button
                    title={`${qrCodeZoomedIn ? "Zoom out" : "Zoom in"}`}
                    className={`my-qr-image ${qrCodeZoomedIn ? "zoomed-in" : ""
                      }`}
                    onClick={() => setQrCodeZoomedIn(!qrCodeZoomedIn)}
                  >
                    <img
                      src={myQrImage}
                      alt={`Use code ${addSpaceBetweenCode(myCode, codeSeparator)}`}
                    />
                  </button>

                  <div className="my-qr-copy">
                    <p className="my-qr-description">
                      Scan the QR or visit Sllinky on another device and enter this code to view links
                    </p>
                    <p className="my-qr-code">{addSpaceBetweenCode(myCode, codeSeparator)}</p>
                  </div>
                </motion.div>
              </AnimatePresence>
            )}

            {myLinkCount > 0 && !isAdmin && (
              <button onClick={getMyLinks} className="my-links-btn">
                <span className="material-icons">arrow_back_ios</span>
                My Links
              </button>
            )}

            {myLinks.length > 0 && (
              <div className="links-list-header">
                <h3>{isAdmin ? "My Links" : getOtherSideLinksTitle()}</h3>
                {isAdmin ? (
                  <button onClick={removeAllLinks}>Remove All</button>
                ) : (
                  <button onClick={openAllLinks}>Open All</button>
                )}
              </div>
            )}

            {!myLinks.length && slinkyCode.length === 9 && (
              <p className="loading-message">{getLoadingMessage()}</p>
            )}


            <AnimatePresence initial={false}>
              {myLinks.length > 0 && (
                <motion.div
                  className="links-list"
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.8, ease: [.43, .06, 0, 1.14] }}
                >
                  {myLinks.map((link, index) => {
                    return (
                      <LinkBlock
                        key={index.toString()}
                        link={link}
                        isAdmin={isAdmin}
                        removeLink={removeLink}
                        copyToClipboard={copyToClipboard}
                        setNudge={setNudge}
                        clearNudgeAfter={clearNudgeAfter}
                      />
                    );
                  })}
                </motion.div>
              )}
            </AnimatePresence>

          </section>

          <AnimatePresence>
            {!myLinks.length && !slinkyCode && (
              <Footer />
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

export default App;