import React from 'react';
import './Footer.scss';
import { motion } from 'framer-motion';

const Footer = () => {
    return (
        <motion.footer
            initial={{ y: 10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.6, ease: "circOut" }}
            exit={{ y: 10, opacity: 0 }}
        >
            Made by{" "}
            <a href="https://akshardave.com" target="_blank" rel="noreferrer">
                Akshar Dave
            </a>{" "}
            and friends at{" "}
            <a href="https://kindgarden.org" target="_blank" rel="noreferrer">
                Kind Garden
            </a>
            .
        </motion.footer>
    );
}

export default Footer;
