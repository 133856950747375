import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.querySelector('sllinky'));
root.render(<App />);

serviceWorkerRegistration.register();

if (process.env.NODE_ENV !== "production") {
  reportWebVitals(console.log);
}