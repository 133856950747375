const firebaseConfig = {
    apiKey: "AIzaSyAtphKplkp3nt5NxA9Oy8GYjs8RKZOvC7A",
    authDomain: "slinky-a4022.firebaseapp.com",
    projectId: "slinky-a4022",
    storageBucket: "slinky-a4022.appspot.com",
    messagingSenderId: "529955886140",
    appId: "1:529955886140:web:ecd60c47379008d45f9f39",
    databaseURL:
        "https://slinky-a4022-default-rtdb.asia-southeast1.firebasedatabase.app",
};

export default firebaseConfig;