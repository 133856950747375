import QRious from "qrious";

const generateQR = (code) => {
    let qr = new QRious({
        value: `${window.location.origin}?code=${code}`,
        padding: 10,
        background: "#fff",
        size: 200,
    });
    let qrSrc = qr.toDataURL("image/jpeg");
    return qrSrc;
};

export default generateQR;