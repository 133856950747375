import React from 'react';
import "@fontsource/inter/900.css";
import './Header.scss';
import sllinkyIcon from '../../images/sllinky.svg';
import { motion, AnimatePresence } from 'framer-motion';

const Header = ({ nudge, setNudge }) => {

    const nudgeVariants = {
        initial: {
            opacity: 0,
            x: -20,
        },
        animate: {
            opacity: 1,
            x: 0,
            transition: {
                duration: 0.4,
                ease: "circOut",
            },
        },
        exit: {
            opacity: 0,
            x: 20,
            transition: {
                duration: 0.2,
                ease: "circOut"
            }
        },
    }

    return (
        <header>
            <img src={sllinkyIcon} alt="" className='slinky-icon' />
            <h1>Sllinky</h1>
            <div className="subtitle">
                <AnimatePresence initial={false}>
                    {nudge && (
                        <motion.span className="nudge"
                            variants={nudgeVariants}
                            initial="initial"
                            animate="animate"
                            exit="exit"
                        >
                            {nudge}
                        </motion.span>
                    )}
                </AnimatePresence>

                <AnimatePresence initial={false}>
                    {
                        !nudge && (
                            <motion.h2
                                variants={nudgeVariants}
                                initial="initial"
                                animate="animate"
                                exit="exit"
                            >
                                Share links in a blink
                            </motion.h2>
                        )
                    }
                </AnimatePresence>

                {/* <h2>Automatically paste links? Yes / No */}
            </div>


            {/* <h2>Share links faster with 1 click. <a href="">See how</a></h2> */}

        </header>
    );
}

export default Header;