import './LinkBlock.scss'

export default function LinkBlock({ link, isAdmin, removeLink, copyToClipboard, setNudge, clearNudgeAfter }) {

    const handleLinkCopy = (link) => {
        copyToClipboard(link);

        setNudge(`Copied ${link.substring(0, 35)}...`);

        clearNudgeAfter(2000);
    }

    return (
        <div
            className="link-block"
        >
            <img
                src={`https://www.google.com/s2/favicons?domain=${link.url}&sz=32`}
                alt=""
                draggable="false"
                loading="eager"
            />

            <a
                href={link.url}
                title={link.url}
                target="_blank"
                rel="noreferrer">
                {link.title ? (
                    <span className="link-title">{link.title}</span>
                ) : <span className="link-label">{link.url}</span>}
            </a>

            {isAdmin ? (
                <button
                    onClick={() => removeLink(link.timestamp)}
                    title="Remove link"
                >
                    <span className="material-icons">close</span>
                </button>
            ) : (
                <button
                    onClick={() => handleLinkCopy(link.url)}
                    title="Copy link"
                >
                    <span className="material-icons">copy</span>
                </button>
            )}
        </div>
    )
}
